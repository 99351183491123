import { AuthProviderName, Environment, Language, MovinmotionApplication } from '../app/shared/models/environment';
import { NgxLoggerLevel } from 'ngx-logger';

export const environment: Environment = {
  production: true,
  defaultLanguage: Language.fr,
  logger: {
    level: NgxLoggerLevel.ERROR,
  },
  useFirebaseEmulator: false,
  firebase: {
    apiKey: 'AIzaSyCpjpi_OaBvMOW14j7WSTyX8hpNv0wvtS0',
    authDomain: 'accounts.app.demo.movinmotion.net',
    databaseURL: 'https://movinmotion-demo.firebaseio.com',
    projectId: 'movinmotion-demo',
    storageBucket: 'movinmotion-demo.appspot.com',
    messagingSenderId: '1077880888766',
    appId: '1:1077880888766:web:0c1d5f3600fd9831ec6def',
  },
  queryParamsKeys: {
    redirectUrl: 'mmr',
    token: 'mmt',
    entropy: 'mment',
    resetPassword: 'mmrp',
    changePassword: 'mmcp',
    email: 'mme',
    autoConnectUrl: 'mmac',
    language: 'mml',
    display: 'mmd',
    providerConnection: 'mmpc',
    movinmotionAuthError: 'mmae',
  },
  displayByAppHostEnabled: true,
  movinmotionAppUrls: {
    [MovinmotionApplication.core]: 'https://entreprise.app.demo.movinmotion.net/public/login',
    [MovinmotionApplication.accounting]: 'https://compta.app.demo.movinmotion.net/public/login',
    [MovinmotionApplication.worker]: 'https://salarie.app.demo.movinmotion.net/login',
    [MovinmotionApplication.social]: 'https://app.demo.movinmotion.net',
    [MovinmotionApplication.talents]: 'https://talents.app.demo.movinmotion.net/film-france/login',
    [MovinmotionApplication.odalie]: 'https://odalie2.app.demo.movinmotion.net',
  },
  providers: {
    [AuthProviderName.myId]: {
      id: 'oidc.disney-myid',
      enabled: false,
    },
    [AuthProviderName.deezer]: {
      id: 'oidc.deezer',
      enabled: true,
    },
    [AuthProviderName.tohero]: {
      id: 'oidc.tohero',
      enabled: true,
    },
    [AuthProviderName.movinmotion]: {
      id: 'oidc.movinmotion',
      enabled: true,
    },
  },
  subscribeUrl: 'https://www.movinmotion.com/inscription/',
  filmFranceSubscribeUrls: {
    worker: 'https://salarie.app.demo.movinmotion.net/film-france-talents/signup',
    talents: 'https://talents.app.demo.movinmotion.net/film-france/signup',
  },
  odalieSubscribeUrl: 'https://odalie2.app.demo.movinmotion.net/cmbSignup',
  envName: 'demo',
  sentryDsn: '',
  thirdParty: {
    mixpanel: {
      enabled: true,
    },
  },
};
